<template>
    <div>
        学科网对接测试
    </div>
</template>

<script>
export default {
    props: [],
    components: {
    },
    data () {
        return {
        };
    },
    watch: {
    },
    methods: {
    },
    created(){
    },
    mounted() {
    }
}
</script>

<style>

</style>